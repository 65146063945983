export const PURPOSE_PRODUCT = {
  hiring: 'assess',
  junior: 'junior',
  ld: 'growth',
};

export const DEFAULT_PASSING_SCORE = 60;

// Not exhaustive yet
export enum TestType {
  PersonalityProfiling = 'Personality Profiling',
}

/**
 * Interview question types
 *
 * These are the types of questions that can be asked in an interview.
 *
 * @enum {string}
 * @property {string} Skill               - Tests a candidate's skill
 * @property {string} Informational       - Provides information to the candidate
 * @property {string} AdditionalCriteria  - Evaluates additional criteria
 * @property {string} Rating              - Overall rating of the candidate
 */
export enum QuestionType {
  Skill = 'Open Ended Free Response',
  Informational = 'Informational',
  AdditionalCriteria = 'Additional Criteria',
  Rating = 'MCQ Rating',
}
