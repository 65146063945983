import jwt_decode from 'jwt-decode';
import { getCurrentTokens } from '../store/actions/auth.actions';
import { hasOrgFeature } from '../Authorization/Check';

interface Decoded {
  ufid: number;
  ut: string;
  paid: boolean;
  org: number;
  grp: number[];
  pl: string[];
  cloaking: boolean;
  assess: boolean;
  junior: boolean;
  growth: boolean;
  interview: boolean;
}

interface FeatureAccess {
  assess: boolean;
  junior: boolean;
  growth: boolean;
  interview: boolean;
}

export interface User {
  id: number;
  type: string;
  paid: boolean;
  organisationId: number;
  groupIds: number[];
  role: string;
  cloaking: boolean;
  access: FeatureAccess;
}

export const OWNER = 'Owner';
export const ADMIN = 'Admin';
export const STANDARD = 'Standard';
export const RESTRICTED = 'Restricted';

export const getUserFromToken = (): User => {
  const decoded = getDecodedToken();
  const user: User = {
    id: decoded.ufid,
    type: decoded.ut,
    paid: decoded.paid,
    organisationId: decoded.org,
    groupIds: decoded.grp,
    role: decoded.pl[0],
    cloaking: decoded.cloaking,
    access: {
      assess: hasOrgFeature('assess'),
      junior: hasOrgFeature('junior'),
      growth: hasOrgFeature('growth'),
      interview: hasOrgFeature('interview'),
    },
  };
  return user;
};

export const hasAdminPrivileges = (): boolean => {
  const decoded = getDecodedToken();
  if (!decoded) {
    return false;
  }
  const user = getUserFromToken();
  return user.role === OWNER || user.role === ADMIN;
};

export const getDecodedToken = (): Decoded => {
  let decoded: Decoded = null;
  const { accessToken } = getCurrentTokens();
  if (accessToken !== 'null' && accessToken !== '') {
    decoded = jwt_decode(accessToken || '');
  }
  return decoded;
};

export const hasPaid = (): boolean => {
  return !!getDecodedToken()?.paid;
};

export const hasCompletedSignup = (accessToken): boolean => {
  if (!accessToken) return false;
  const decodedToken: { fname?: string } = jwt_decode(accessToken);
  return decodedToken?.fname != null;
};
