import jwt_decode from 'jwt-decode';
import authorization from '../AuthorizationData/authorization.json';
import store from '../store/store';

export interface Decoded {
  ufid: number;
  pl: string;
  paid: boolean;
  org: number;
  grp: any;
  force_sso: boolean;
  org_features: any;
}
interface Assessment {
  is_sample: boolean;
}

let { accessToken } = store.getState().auth;
const updateAccessToken = (): void => {
  accessToken = store.getState().auth.accessToken;
  decoded = getClaimsFromAccessToken();
};
store.subscribe(updateAccessToken);

const authorize = (target: string, action: string, owner?: number): boolean => {
  const authorization_data = authorization[decoded.pl[0]];
  if (
    !authorization_data ||
    !authorization_data[target] ||
    !authorization_data[target][action]
  ) {
    return false;
  }
  switch (authorization_data[target][action]) {
    case 'no':
      return false;
    case 'own':
      if (!owner || decoded.ufid !== owner || decoded.ufid === 0) {
        return false;
      }
      return true;
    default:
      return true;
  }
};

const getClaimsFromAccessToken = (): Decoded => {
  let decodedJwt: Decoded = {
    ufid: 0,
    pl: '',
    paid: null,
    org: 0,
    grp: [],
    force_sso: false,
    org_features: [],
  };
  if (accessToken && accessToken !== 'undefined') {
    decodedJwt = jwt_decode(accessToken);
  }
  return decodedJwt;
};
let decoded = getClaimsFromAccessToken();

export const isRestricted = (assessment: Assessment): boolean => {
  return !decoded.paid && !assessment.is_sample;
};

export const doesAccountForceSSO = (): boolean => {
  return decoded.force_sso;
};

export const hasOrgFeature = (feature: string): boolean => {
  return (
    typeof decoded.org_features === 'undefined' ||
    typeof decoded.org_features[feature] === 'undefined' ||
    decoded.org_features[feature]
  );
};

export default authorize;
