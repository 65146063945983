import { handleError } from 'handleError';
import store, { AppThunk } from '../store';
import { getSubscription as getSubscriptionApi } from '../../api/subscription.api';
import {
  getSubscriptionStart,
  getSubscriptionSuccess,
  getSubscriptionFailure,
} from '../reducers/subscription';

export const getSubscription = (orgId: number): AppThunk => async (
  dispatch
) => {
  const { subscription } = store.getState();
  if (subscription?.loading) {
    return;
  }
  dispatch(getSubscriptionStart());
  getSubscriptionApi(orgId)
    .then((data) => {
      dispatch(getSubscriptionSuccess(data.data));
    })
    .catch((err) => {
      dispatch(getSubscriptionFailure(err.message));
      err.stack = new Error().stack;
      handleError(err);
    });
};
