import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import LoadingOverlay from 'react-loading-overlay-ts';
import NoAccess from 'components/Assessment/NoAccess';
import BounceLoader from 'react-spinners/BounceLoader';
import { startLoading } from 'store/reducers/app';
import PasswordResetPage from 'pages/PasswordResetPage';
import TemporarySignUp from 'components/TemporarySignUp/TemporarySignUp';
import { ToastProvider } from 'react-toast-notifications';
import { Snack } from 'components/Shared/Modal/snack';
import {
  AssessmentCandidatesDownloadPage,
  AssessmentPage,
  AssessmentReportDownloadPage,
  CandidateDetailsPage,
  GroupsPage,
  HiringAssessments,
  HiringTeamPage,
  InsightsPage,
  JuniorAssessments,
  LDAssessments,
  SearchCandidatesPage,
  SmartRecruitersIntegrationPage,
  TemplateListPage,
} from 'pages';
import useAuthRedirect from 'hooks/useAuthRedirect';
import CompareCandidatesPage from 'pages/CompareCandidatesPage';
import NPSUnsubscribed from 'components/NPSUnsubscribed';
import SubscriptionPage from 'components/SignupFlow/SubscriptionPage';
import AddG2Review from 'components/NpsLanding/AddG2Review';
import AccountSetup from 'components/SignupFlow/AccountSetup';
import NoActiveSubscription from 'components/SignupFlow/NoActiveSubscription';
import { hasOrgFeature } from './Authorization/Check';
import { getSubscription } from './store/actions/subscription.actions';
import { RootState } from './store/rootReducer';
import Logout from './components/Logout/Logout';
import {
  Layout,
  PrivateRoute,
} from './components/Shared/PrivateRoute/PrivateRoute';
import PersonalDetailsPage from './pages/PersonalDetailsPage';
import OrgDetailsPage from './pages/OrganizationDetails';
import OrgTemplatesPage from './pages/OrganizationTemplates';
import EditTemplatePage from './pages/EditTemplate';
import BillingPage from './pages/BillingPage';
import IntegrationAuthPage from './pages/IntegrationAuthPage';
import LoginPage from './pages/LoginPage';
import CreateInterviewIntroPage from './pages/CreateInterviewIntroPage';
import EditInterviewPage from './pages/EditInterviewPage';
import NotFound from './components/NotFound';
import Forbidden from './components/Forbidden';
import './styles/spacing.styles.scss';
import './styles/fonts.styles.scss';
import MFAPage from './pages/MFAPage';
import CandidateResults from './components/CandidateResults';
import { PublicRoute } from './components/Shared/PublicRoute/PublicRoute';
import SsoConfiguration from './components/SsoConfiguration';
import RecruiterInvitationPage from './pages/RecruiterInvitationPage';
import CandidateReportPage from './pages/CandidateReportPage';
import NpsLanding from './components/NpsLanding';
import { PagePermission } from './components/Menu/Menu';

export interface Decoded {
  ufid: number;
}

const Routes: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname, hash, key } = useLocation();
  const { loading } = useSelector((state: RootState) => state.app);
  const { accessToken } = useSelector((state: RootState) => state.auth);
  const { subscription } = useSelector(
    (state: RootState) => state.subscription
  );
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const orgId = userDetails?.recruiter_detail?.organisation_id;

  const assess = hasOrgFeature('assess');
  const junior = hasOrgFeature('junior');
  const growth = hasOrgFeature('growth');

  useAuthRedirect(accessToken);

  // Whenever the path changes, scroll to the appropiate part of the page
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  // Need to get current subscription if not loaded in redux store yet
  useEffect(() => {
    if (orgId && !subscription) {
      dispatch(getSubscription(orgId));
    }
  }, [orgId, subscription, dispatch]);

  const TemplateListComponent = useCallback((feature, hasPermission) => {
    if (hasPermission) {
      return <TemplateListPage feature={feature} />;
    }
    return <NoAccess feature="junior" />;
  }, []);
  const AssessTemplateListComponent = useCallback(() => {
    return TemplateListComponent('assess', assess);
  }, [TemplateListComponent, assess]);
  const JuniorTemplateListComponent = useCallback(() => {
    return TemplateListComponent('junior', junior);
  }, [TemplateListComponent, junior]);
  const GrowthTemplateListComponent = useCallback(() => {
    return TemplateListComponent('growth', growth);
  }, [TemplateListComponent, growth]);

  if (!PagePermission(accessToken, pathname)) {
    return <NoAccess feature="permission" />;
  }

  return (
    <ToastProvider
      autoDismiss
      autoDismissTimeout={5000}
      // @ts-ignore
      components={{ Toast: Snack }}
    >
      <LoadingOverlay
        active={loading}
        style={{ height: '100vh !important' }}
        spinner={<BounceLoader color="gray" />}
      >
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/password-reset" component={PasswordResetPage} />
          <Route exact path="/login-mfa/:mfaId" component={MFAPage} />
          <Route
            exact
            path="/linkedin-login-callback"
            component={LinkedInPopUp}
          />
          <Route
            exact
            path="/recruiter-invitation/:activationCode"
            component={RecruiterInvitationPage}
          />
          <Route exact path="/sign-up" component={TemporarySignUp} />
          <Route exact path="/logout" component={Logout} />
          <PrivateRoute
            exact
            path="/"
            component={() => {
              dispatch(startLoading());
              const dashboard = localStorage.getItem('@alooba/dashboard');
              if (dashboard) {
                window.location.href = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/${dashboard}`;
              } else {
                window.location.href = `${process.env.REACT_APP_URL}/alooba-assess`;
              }
              return null;
            }}
          />

          <Route
            exact
            path="/alooba-for-work-dashboard"
            render={() => <Redirect to="/alooba-assess" />}
          />
          <Route
            exact
            path="/hiring-team"
            render={() => <Redirect to="/manage-users" />}
          />
          <PrivateRoute
            exact
            path="/alooba-assess"
            component={() => {
              if (assess) {
                return <HiringAssessments />;
              }
              return <NoAccess feature="assess" />;
            }}
          />
          <PrivateRoute
            exact
            path="/alooba-junior"
            component={() => {
              if (junior) {
                return <JuniorAssessments />;
              }
              return <NoAccess feature="junior" />;
            }}
          />
          <PrivateRoute
            exact
            path="/alooba-growth"
            component={() => {
              if (growth) {
                return <LDAssessments />;
              }
              return <NoAccess feature="growth" />;
            }}
          />
          <PrivateRoute
            exact
            path="/alooba-assess/create-from-template"
            layout={Layout.Minimal}
            component={AssessTemplateListComponent}
          />
          <PrivateRoute
            exact
            path="/alooba-junior/create-from-template"
            layout={Layout.Minimal}
            component={JuniorTemplateListComponent}
          />
          <PrivateRoute
            exact
            path="/alooba-growth/create-from-template"
            layout={Layout.Minimal}
            component={GrowthTemplateListComponent}
          />
          <PrivateRoute
            exact
            path="/alooba-growth/insights"
            component={() => {
              if (growth) {
                return <InsightsPage />;
              }
              return <NoAccess feature="growth" />;
            }}
          />
          <PrivateRoute
            exact
            path="/assessment/:assessmentId-:formattedAssessmentName/:tabName"
            component={AssessmentPage}
          />
          <PublicRoute
            exact
            path="/test-completed/:reference/:tabName"
            showChatbot
            component={CandidateResults}
          />
          <PrivateRoute
            exact
            path="/candidate-details/:reference/:tabName"
            component={CandidateDetailsPage}
          />
          <Route
            exact
            path="/candidate-details/:reference/"
            render={(props) => (
              <Redirect
                to={`/candidate-details/${props.match.params.reference}/overview`}
              />
            )}
          />
          <PrivateRoute
            exact
            layout={Layout.None}
            path="/candidate-report/:reference/"
            component={CandidateReportPage}
          />
          <PrivateRoute
            exact
            path="/candidate-details/:reference/:tabName/:partIndex"
            component={CandidateDetailsPage}
          />
          <PrivateRoute
            exact
            path="/compare-candidates/:assessmentId-:formattedAssessmentName/:reference/:secondReference?"
            component={CompareCandidatesPage}
          />
          <PrivateRoute
            exact
            path="/expert-admin-dashboard"
            component={() => {
              dispatch(startLoading());
              window.location.href = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/expert-admin-dashboard`;
              return null;
            }}
          />
          <PrivateRoute
            exact
            path="/publisher-dashboard"
            component={() => {
              dispatch(startLoading());
              window.location.href = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/publisher-dashboard`;
              return null;
            }}
          />
          <PrivateRoute
            exact
            path="/user-dashboard"
            component={() => {
              dispatch(startLoading());
              window.location.href = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/user-dashboard`;
              return null;
            }}
          />
          <PrivateRoute
            exact
            path="/expert-dashboard"
            component={() => {
              dispatch(startLoading());
              window.location.href = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/expert-dashboard`;
              return null;
            }}
          />
          <PrivateRoute
            exact
            path="/add-candidate"
            component={() => {
              dispatch(startLoading());
              window.location.href = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/add-candidate`;
              return null;
            }}
          />
          <PrivateRoute
            exact
            path="/all-candidates"
            component={() => {
              dispatch(startLoading());
              window.location.href = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/all-candidates`;
              return null;
            }}
          />
          <PrivateRoute
            exact
            path="/assign-assessment"
            component={() => {
              dispatch(startLoading());
              window.location.href = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/assign-assessment`;
              return null;
            }}
          />
          <PrivateRoute
            exact
            path="/organization-settings"
            component={OrgDetailsPage}
          />
          <PrivateRoute
            exact
            path="/organization-templates"
            component={OrgTemplatesPage}
          />
          <PrivateRoute
            exact
            path="/organization-templates/:templateName"
            component={EditTemplatePage}
          />
          <PrivateRoute exact path="/billing" component={BillingPage} />
          <PrivateRoute
            exact
            path="/sso-configuration"
            component={SsoConfiguration}
          />
          <PrivateRoute exact path="/groups" component={GroupsPage} />
          <PrivateRoute exact path="/manage-users" component={HiringTeamPage} />
          <PrivateRoute
            exact
            path="/personal-details"
            component={PersonalDetailsPage}
          />
          <PrivateRoute
            exact
            path="/question-bank"
            component={() => {
              dispatch(startLoading());
              window.location.href = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/question-bank`;
              return null;
            }}
          />
          <PrivateRoute
            exact
            path="/search-candidates"
            component={SearchCandidatesPage}
          />
          <PrivateRoute
            exact
            path="/interview/create"
            layout={Layout.Minimal}
            component={CreateInterviewIntroPage}
          />
          <PrivateRoute
            exact
            path="/interview/:interviewId/edit"
            layout={Layout.Minimal}
            component={EditInterviewPage}
          />
          <PrivateRoute
            path="/integration/:partner/auth"
            component={IntegrationAuthPage}
          />
          <PrivateRoute
            layout={Layout.None}
            path="/integration/smart-recruiters"
            showChatbot={false}
            component={SmartRecruitersIntegrationPage}
          />
          <PrivateRoute
            exact
            path="/download-report/:fileName"
            layout={Layout.Minimal}
            component={AssessmentReportDownloadPage}
          />
          <PrivateRoute
            exact
            path="/download-export/:fileName"
            layout={Layout.Minimal}
            component={AssessmentCandidatesDownloadPage}
          />
          <PrivateRoute
            exact
            path="/complete-account-setup"
            layout={Layout.Minimal}
            hasBars={false}
            component={AccountSetup}
          />
          <PrivateRoute
            exact
            path="/choose-subscription-plan"
            layout={Layout.Minimal}
            hasBars={false}
            component={SubscriptionPage}
          />
          <PrivateRoute
            exact
            path="/no-active-subscription"
            layout={Layout.Minimal}
            hasBars={false}
            component={NoActiveSubscription}
          />
          <Route
            exact
            path="/feedback/nps/:code/unsubscribed"
            component={NPSUnsubscribed}
          />
          <Route exact path="/feedback/nps/:code" component={NpsLanding} />
          <Route
            exact
            path="/feedback/nps/:code/g2-review"
            component={AddG2Review}
          />
          <Route exact path="/forbidden" component={Forbidden} />
          <Route component={NotFound} />
        </Switch>
      </LoadingOverlay>
    </ToastProvider>
  );
};

export default Routes;
