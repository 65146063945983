import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionResult, ChargeResult } from 'api/subscription.api';

interface SubscriptionState {
  subscription: SubscriptionResult | null;
  latest_charge: ChargeResult | null;
  loading: boolean;
  error: string | null;
}

interface SubscriptionResponse {
  data: {
    subscription: SubscriptionResult;
    latest_charge: ChargeResult | null;
  };
}

const initialState: SubscriptionState = {
  subscription: null,
  latest_charge: null,
  loading: null,
  error: null,
};

const subscriptionReducer = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    getSubscriptionStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getSubscriptionSuccess: (
      state,
      action: PayloadAction<SubscriptionResponse>
    ) => {
      state.subscription = action.payload.data?.subscription;
      state.latest_charge = action.payload.data?.latest_charge;
      state.loading = false;
    },
    getSubscriptionFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  getSubscriptionFailure,
  getSubscriptionSuccess,
  getSubscriptionStart,
} = subscriptionReducer.actions;
export default subscriptionReducer.reducer;
