import api from './index';

export interface SubscriptionResult {
  id: number;
  plan: {
    id: number;
    name: string;
    description: string;
    monthly_credits: number;
  };
  ends_at: string;
  status: string;
  trial_ends_at: string;
  renews_at: string;
  period_months: number;
  subscription_credits: number;
}

export interface ChargeResult {
  currency: string;
  amount: number;
  status: string;
  paid: boolean;
  description: string;
  failure_message: string;
  payment_method_type: string;
  created_at: string;
}

export async function getSubscription(orgId: number): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}/subscription` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = api.get(url);
  return response;
}

export async function getSubscriptionPlans(): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/subscriptions/plans` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = api.get(url);
  return response;
}

export async function subscribe(
  orgId: number,
  price_id: string,
  currency: string,
  billing_name: string,
  billing_email: string,
  billing_country: string,
  abn?: string,
  postalcode?: string
): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}/subscription` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.post(url, {
    price_id,
    currency,
    billing_name,
    billing_email,
    billing_country,
    abn,
    postalcode,
  });
}

export async function getCustomerPortalLink(orgId: number): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}/billing/portal` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}

export async function checkTrialCode(code: string): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/check-trial-code/${code}` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}

export async function startFreeTrial(
  orgId: number,
  trial_code: string,
  billing_name: string,
  billing_email: string,
  billing_country: string,
  abn?: string,
  postalcode?: string
): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/start-free-trial/${orgId}` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.post(url, {
    trial_code,
    billing_name,
    billing_email,
    billing_country,
    abn,
    postalcode,
  });
}
