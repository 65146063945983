import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  ReactElement,
  useContext,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/rootReducer';
import Skeleton from 'react-loading-skeleton';
import ParticipantsBlank from 'components/Participants/ParticipantsBlank';
import ParticipantsTable from 'components/Participants/ParticipantsTable';
import AddParticipants from 'components/Participants/addParticipants';
import TextWithTooltip from 'components/Shared/Tooltip/TextWithTooltip';
import Switcher from 'components/Shared/Switcher';
import ErasedCandidateInfo from 'components/Shared/Erased/ErasedCandidateInfo';
import { getAssessmentCandidates } from 'api/assessment.api';
import { LoaderContext } from 'context/loader';
import { relativeDate, fullDateTime } from 'helpers/datetime';
import { selectCandidateRow } from 'helpers/store';
import { DynamicTablePropsContext } from 'components/Shared/DynamicTable/context/DynamicTablePropsContext';
import ActionMenuContainer from 'components/Participants/ActionMenuContainer';
import useDynamicTable from 'hooks/useDynamicTable';
import StarIcon from '../../images/icons/star.svg';
import LightTooltip from '../Shared/Tooltip/LightTooltip';
import { assessmentContext } from '../Assessment';
import {
  selectFields,
  tableFields,
  statusTooltip,
} from './assessmentTableFields';
import { getOrgDetails } from '../../store/actions/organization.actions';
import { toggleParticipantsView } from '../../store/reducers/assessment';
import {
  updateParticipantsQueryParams,
  setParticipantsPaginator,
  setParticipantsCurrentPage,
  setParticipantsSearch,
  resetAssessmentResultsCache,
  setAssessmentId,
} from '../../store/reducers/assessmentCandidate';
import { RefreshContext } from './context/refresh';
import ParticipantsKanban from './ParticipantsKanban';
import './index.scss';
import { getTimezones } from '../../api/profile-api';

const getDataCallback = {
  callback: (data: any): any => {
    return data;
  },
};

const Participants: React.FC = () => {
  const dispatch = useDispatch();
  const { assessment, loading, setAssessment } = useContext(assessmentContext);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [timezones, setTimezones] = useState([]);
  const setQueryParams = updateParticipantsQueryParams;
  const setPaginator = setParticipantsPaginator;
  const setCurrentPage = setParticipantsCurrentPage;
  const setSearch = (searchTerm): void => {
    dispatch(setParticipantsSearch(searchTerm));
  };

  const { userDetails } = useSelector((state: RootState) => state.profile);
  const { participantsView } = useSelector(
    (state: RootState) => state.assessment
  );
  const { organizationDetails: organization } = useSelector(
    (state: RootState) => state.organization
  );
  const cachedAssessmentId = useSelector(
    (state: RootState) => state.assessmentCandidate.assessmentId
  );
  const participantsSearch = useSelector(
    (state: RootState) => state.assessmentCandidate.participantsSearch
  );
  const { forceRefresh } = useSelector((state: RootState) => state.utils);
  if (assessment && assessment.id !== cachedAssessmentId) {
    dispatch(resetAssessmentResultsCache());
    dispatch(setAssessmentId(assessment.id));
  }
  const {
    participantsPaginator: paginator,
    participantsQueryParams: queryParams,
    participantsCurrentPage: currentPage,
    participantsSearch: search,
  } = useSelector((state: RootState) => state.assessmentCandidate);

  const {
    isLast,
    nextPage,
    filterSearch,
    changePerPage,
    sortingAction,
    tablePaginatorProps,
  } = useDynamicTable(getDataCallback, paginator, currentPage, setSearch);

  const [participantsTableProps, setParticipantsTableProps] = useState<any>({
    loading: isLoading,
    fields: selectFields,
    data: [],
    paginator,
  });
  const [blank, setBlank] = useState<boolean>(true);
  const getData = async (data: any): Promise<any> => {
    setIsError(false);
    setIsLoading(true);
    const state = { ...queryParams, ...data, assessmentId: assessment.id };
    dispatch(setQueryParams(state));
    const res = await getAssessmentCandidates(state);
    if (res?.data?.data) {
      dispatch(setPaginator(res.data.data));
      setBlank(
        firstLoad && !participantsSearch ? res.data.data.total === 0 : false
      );
      dispatch(setCurrentPage(res.data.data.current_page));
    } else {
      setIsError(true);
    }
    setIsLoading(false);
    setLoaded(true);
  };
  getDataCallback.callback = useCallback(getData, [
    dispatch,
    setCurrentPage,
    setPaginator,
    setQueryParams,
    queryParams,
    firstLoad,
    assessment.id,
    participantsSearch,
  ]);

  const retry = (): void => {
    getDataCallback.callback({});
  };

  useEffect(() => {
    getTimezones().then((res) => {
      const formattedTimezones =
        res &&
        res.data &&
        res.data.data.map((zone) => {
          return {
            label: `(GMT${zone.offset}) ${zone.code}`,
            code: zone.code,
            offset: zone.offset,
          };
        });
      setTimezones(formattedTimezones);
    });
  }, []);
  useEffect(() => {
    if (firstLoad) {
      if (paginator.total === undefined) {
        setLoaded(false);
        getDataCallback.callback({});
      } else {
        setLoaded(true);
        if (!participantsSearch) {
          setBlank(paginator.total === 0);
        }
      }
      setFirstLoad(false);
    }
  }, [firstLoad, paginator.total, participantsSearch]);
  useEffect(() => {
    const orgId = userDetails?.recruiter_detail?.organisation_id;
    if (orgId) {
      dispatch(getOrgDetails(orgId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);
  useEffect(() => {
    getDataCallback.callback({});
  }, [forceRefresh]);
  const getPath = useCallback((row): string => {
    if (process.env.REACT_APP_NEW_CANDIDATE_RESULTS) {
      return `/candidate-details/${row.Reference}/overview`;
    }
    return `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/individual-candidate-result/${row.Reference}`;
  }, []);
  const processCell = useCallback(
    (row: any, field: any): any => {
      if (!row[field.label]) {
        return 'N/A';
      }
      const value = row[field.label];
      const isErased =
        row.data_erasure_request_id !== null &&
        row.data_erasure_request_id !== undefined;
      if (isErased && field.label === 'Name') {
        return (
          <ErasedCandidateInfo placement="top" participantType="participant" />
        );
      }
      if (field.type === 'datetime') {
        if (
          field.label === 'Expires On' &&
          ['Completed', 'Requires Evaluation'].includes(row.Status)
        ) {
          return '-';
        }
        return (
          <TextWithTooltip
            text={relativeDate(value, userDetails.user_timezone)}
            tooltip={fullDateTime(
              value,
              userDetails.user_timezone,
              'll [at] LT [(GMT]Z[)]'
            )}
          />
        );
      }
      if (field.label === 'Status') {
        if (value === 'Requires Evaluation') {
          return (
            <div className="primary-color last-col">
              <TextWithTooltip
                text={value}
                tooltip={`${row.Name} ${statusTooltip[value] || null}`}
              />
            </div>
          );
        }
        return (
          <div className="primary-color last-col">
            <TextWithTooltip
              text={value}
              tooltip={`${row.Name} ${statusTooltip[value] || null}`}
            />
          </div>
        );
      }
      if (field.label === 'Name') {
        return <div className="primary-color underline-on-hover">{value}</div>;
      }
      if (field.label === 'Reference') {
        return (
          <div className="reference text-muted underline-on-hover">
            {value}
            <div className="candidate-flags">
              {row['Resume Match'] && (
                <LightTooltip
                  title="Potential Star Candidate. Analysis of their resume indicates they may be a good fit for this role."
                  placement="top"
                  arrow
                >
                  <img src={StarIcon} alt="Star Candidate" />
                </LightTooltip>
              )}
            </div>
          </div>
        );
      }
      return value;
    },
    [userDetails.user_timezone]
  );
  const shouldCloakPII = useMemo(() => {
    return (
      userDetails?.recruiter_detail?.cloak_candidates ||
      organization.cloak_candidates ||
      assessment.cloak_candidates
    );
  }, [userDetails, organization, assessment]);

  const viewCandidateDetails = (row): void => {
    selectCandidateRow(row);
  };
  const getFields = useCallback((): any => {
    if (shouldCloakPII) {
      return tableFields.map((field) => {
        if (!field.cloak) {
          return field;
        }
        return {
          hidden: true,
        };
      });
    }
    return tableFields;
  }, [shouldCloakPII]);
  const actionMenu = useCallback(
    (row: any): ReactElement => {
      return (
        <ActionMenuContainer
          {...{ row, assessment, userDetails, shouldCloakPII }}
          getData={getDataCallback.callback}
        />
      );
    },
    [assessment, userDetails, shouldCloakPII]
  );

  useEffect(() => {
    if (assessment.purpose === 'ld' && participantsView === 'kanban') {
      dispatch(toggleParticipantsView());
    }
  }, [assessment.purpose, participantsView, dispatch]);

  useEffect(() => {
    if (!loaded || blank) {
      return;
    }
    const sortingProps = {
      sortingAction,
      orderBy: queryParams.orderBy,
      orderDirection: queryParams.orderDirection,
    };
    setParticipantsTableProps({
      loading: false,
      data: paginator.data,
      fields: getFields(),
      tablePaginatorProps,
      tableSearchProps: {
        filterSearch,
        searchTerm: queryParams.searchTerm,
      },
      sortingProps,
      actionMenu,
      getPath,
      processCell,
      openInNewTab: !process.env.REACT_APP_NEW_CANDIDATE_RESULTS,
      onClickRow: viewCandidateDetails,
    });
    setLoaded(false);
  }, [
    loaded,
    currentPage,
    paginator,
    setQueryParams,
    search,
    queryParams,
    blank,
    userDetails.user_timezone,
    userDetails,
    assessment,
    filterSearch,
    nextPage,
    changePerPage,
    sortingAction,
    getFields,
    actionMenu,
    processCell,
    isLast,
    getPath,
    tablePaginatorProps,
  ]);

  return (
    <>
      <RefreshContext.Provider
        value={[firstLoad, setFirstLoad, getDataCallback, timezones]}
      >
        <LoaderContext.Provider value={[isLoading, setIsLoading]}>
          <div className="participants-top-bar">
            {assessment.purpose === 'ld' ? (
              ''
            ) : (
              <div className="kanban-switcher">
                <label htmlFor="kanban-switcher">Hiring status view</label>
                <Switcher
                  id="kanban-switcher"
                  checked={participantsView === 'kanban'}
                  onChange={() => dispatch(toggleParticipantsView())}
                />
              </div>
            )}
            <div id="participants" className="container">
              <h2 className="section-title">
                {loading && <Skeleton height={20} width={200} />}
                {!loading && assessment.purpose === 'ld'
                  ? 'Participants'
                  : 'Candidates'}
              </h2>
              <AddParticipants
                {...{ assessment, loading, userDetails, setAssessment }}
              />
            </div>
          </div>
          {isError && (
            <ParticipantsBlank
              {...{ loading: isLoading }}
              message={`Unable to fetch ${
                assessment.purpose === 'ld' ? 'participants' : 'candidates'
              }.`}
              onRetry={retry}
            />
          )}

          {!isError &&
            (blank ? (
              <ParticipantsBlank
                loading={isLoading}
                message={`No ${
                  assessment.purpose === 'ld' ? 'participants' : 'candidates'
                } added yet.`}
              />
            ) : (
              <>
                {participantsView === 'table' ? (
                  <DynamicTablePropsContext.Provider
                    value={[participantsTableProps, setParticipantsTableProps]}
                  >
                    <ParticipantsTable />
                  </DynamicTablePropsContext.Provider>
                ) : (
                  <ParticipantsKanban
                    cloakCandidates={shouldCloakPII}
                    assessmentId={assessment.id}
                  />
                )}
              </>
            ))}
        </LoaderContext.Provider>
      </RefreshContext.Provider>
    </>
  );
};
export default Participants;
